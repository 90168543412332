<template>
  <b-modal
    id="allocate-i-release-modal"
    title="Allocate Releases to Interface"
    size="lg"
    @ok="submitFunction"
    @shown="syncAllocations"
  >
    <b-button variant="outline-primary" @click="$bvModal.show('add-i-release-allocate-modal')">
      Create & Allocate Release
    </b-button>
    <hr>

    <b-row>
      <b-col cols="5">
        <b-form-group label="All Releases:">
          <div v-if="!rel_loading" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_releases" :options="all_releases" :select-size="5" multiple />
        </b-form-group>

      </b-col>
      <b-col cols="2">
        <b-button-group class="mt-5">
          <b-button
            :disabled="selected_allocated_releases.length === 0"
            @click="removeAllocated"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button
            :disabled="selected_releases.length === 0"
            @click="addAllocated"
          >
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="Associated Releases:">
          <div v-if="!rel_loading" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_allocated_releases" :options="allocated_releases" :select-size="5" multiple />
        </b-form-group>

      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" :disabled="loading_status" @click="ok()">

        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Allocating...
        </span>

        <span v-else>
          Allocate
        </span>

      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>

    </template>
    <AddReleaseAllocate />
  </b-modal>
</template>
<script>

import { mapState } from 'vuex'
import AddReleaseAllocate from '@/components/Domain/Modals/Interfaces/AddReleaseAllocate.vue'

export default {
  components: {
    AddReleaseAllocate,
  },
  data: () => ({
    loading_status: false,
    selected_releases: [],
    selected_allocated_releases: [],
    all_releases: [],
    allocated_releases: [],
    rel_loading: false,
  }),
  computed: {
    ...mapState('interfaces', ['selectedInterface']),
  },
  methods: {
    submitFunction(evt) {
      const selectedId = this.selectedInterface.id
      const modelId = this.$store.state.model.id
      evt.preventDefault()
      this.loading_status = true
      this.$http.get(`/v1/legacy/interfaces/get_interface_releases/${selectedId}`, { params: { model: modelId } }).then(({ data }) => {
        this.$http.post('/v1/legacy/interfaces/disassociate_releases_from_interface', {
          interface: selectedId,
          model: modelId,
          releases: data.map(x => x.id).filter(n => !this.allocated_releases.includes(n)),
        }).then(() => {
          this.$http.post('/v1/legacy/interfaces/associate_releases_with_interface', {
            interface: selectedId,
            model: modelId,
            releases: this.allocated_releases,
          }).then(() => {
            this.$store.dispatch('interfaces/selectInterface', selectedId)
            this.$emit('updated')
            this.loading_status = false
            this.$bvModal.hide('allocate-i-release-modal')
          })
        })
      })
    },
    syncAllocations() {
      const selectedId = this.selectedInterface.id
      const modelId = this.$store.state.model.id
      this.$http.get('/v1/legacy/releases', { params: { model: modelId } })
        .then(({ data }) => {
          console.log('Releases: ', data)
          this.all_releases = data.map(x => ({
            value: x.id,
            html: x.name,
          }))
          this.rel_loading = true
        })
      this.$http.get(`/v1/legacy/interfaces/get_interface_releases/${selectedId}`, { params: { model: modelId } })
        .then(({ data }) => {
          console.log('Allocated Releases: ', data)
          this.allocated_releases = data.map(x => ({
            value: x.id,
            html: x.name,
          }))
        })
    },
    addAllocated() {
      this.selected_releases.forEach(e => {
        if (this.allocated_releases.indexOf(e) === -1) {
          this.allocated_releases.push(e)
        }
      })
      this.selected_releases = []
    },
    removeAllocated() {
      this.selected_allocated_releases.forEach(e => {
        this.allocated_releases.splice(this.allocated_releases.indexOf(e), 1)
      })
      this.selected_allocated_releases = []
    },

  },
}
</script>
